import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <footer className="ftrsec">
            <div className="ftrbtm py-3">
                <div className="container px-lg-5">
                    <div className="row text-center">
                        <div className="col-sm-6">
                            <p className="ptxt mb-sm-0 mb-2 text-sm-start"><span>© Identiqa Consulting Private Limited</span> | <span><Link to="privacy-policy">Privacy Policy</Link></span></p></div>
                        <div className="col-sm-6">
                            <p className="ptxt mb-0 text-sm-end">Website designed by: KARV Design | <a href="https://www.karvdesign.co.in" target='_blank' rel="noopener">www.karvdesign.co.in</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer