import React from 'react'
import ooimg01 from '../assets/images/ooimg01.png'
import ooimg02 from '../assets/images/ooimg02.png'
import ooimg03 from '../assets/images/ooimg03.png'
import ooimg04 from '../assets/images/ooimg04.png'
import ooimg05 from '../assets/images/ooimg05.png'
import ooimg06 from '../assets/images/ooimg06.png'
import ooimg07 from '../assets/images/ooimg07.png'
import bkbtn from '../assets/images/bkbtn.png'
import { Link } from 'react-router-dom'
// import back_nav_icon from '../assets/images/left-arrow-back-svgrepo-com (1).svg'

function OurOfferings() {
	return (
		<div className="inr_sec oosec">
			<div className="container">
				<div className="boxsec">
					<div className="backnav tp">
						<Link to="/"><img src={bkbtn} alt="bkbtn" srcset="" /> </Link>
					</div>
					<div className="hedbox">
						<h3 className="h3txt tparwimg arwimg">OUR OFFERINGS</h3>
						<p className="ptxt">We strive to deliver exceptional, customer-focused solutions that drive client success and satisfaction and to achieve and maintain a leading position in the IT industry through excellence in products, services, and customer relations. Our goal is to expand and solidify our presence in global markets, offering world-class IT solutions worldwide through the various infrastructure services that we offer to our clients -</p>
					</div>
					<div className="secbox"
						data-aos="zoom-in-up"
						data-aos-duration="1000"
						data-aos-easing="ease-in-sine"
						data-aos-anchor-placement="top-center"

					>
						<div className="row">
							<div className="col-md-7">
								<div className="lftbox">
									<h4 className="h4txt arwimg lftarwimg">NETWORK SERVICES</h4>
									<p className="ptxt">We provide technical services in the design and Building of network architecture. These services are essential for the efficient operation and management of computer networks, supporting both individual users and organizational needs.</p>
								</div>
							</div>
							<div className="col-md-5">
								<div className="rytbox">
									<picture className="imgbox"> <img src={ooimg01} alt="ooimg01" /> </picture>
								</div>
							</div>
						</div>
					</div>
					<div className="secbox"

						data-aos="zoom-in-up"
						data-aos-duration="1000"
						data-aos-easing="ease-in-sine"
						data-aos-anchor-placement="top-center"
					>
						<div className="row">
							<div className="col-md-7 order-md-2">
								<div className="rytbox">
									<h4 className="h4txt arwimg rytarwimg">DATACENTRE SERVICES</h4>

									<p className="ptxt">These services are critical for businesses, organizations, and individuals who need reliable and secure data management solutions. We provide technical services across Virtualization, storage, HCI, Disaster recovery, and migration.</p>
								</div>
							</div>
							<div className="col-md-5 order-md-1">
								<div className="lftbox">
									<picture className="imgbox"> <img src={ooimg02} alt="ooimg02" /> </picture>
								</div>
							</div>
						</div>
					</div>
					<div className="secbox"

						data-aos="zoom-in-up"
						data-aos-duration="1000"
						data-aos-easing="ease-in-sine"
						data-aos-anchor-placement="top-center"
					>
						<div className="row">
							<div className="col-md-7">
								<div className="lftbox">
									<h4 className="h4txt arwimg lftarwimg">SECURITY SERVICES</h4>
									<p className="ptxt">We Consult and Implement a Security Framework for Information Security and Data Security, designed to protect networks, data applications, and infrastructure from unauthorized access, cyberattacks, and other threats.</p>
								</div>
							</div>
							<div className="col-md-5">
								<div className="rytbox"
									data-aos="zoom-in-up"
									data-aos-duration="1000"
									data-aos-easing="ease-in-sine">
									<picture className="imgbox"> <img src={ooimg03} alt="ooimg03" /> </picture>
								</div>
							</div>
						</div>
					</div>
					<div className="secbox"

						data-aos="zoom-in-up"
						data-aos-duration="1000"
						data-aos-easing="ease-in-sine"
						data-aos-anchor-placement="top-center"
					>
						<div className="row">
							<div className="col-md-7 order-md-2">
								<div className="rytbox">
									<h4 className="h4txt arwimg rytarwimg">CLOUD SERVICES</h4>
									<p className="ptxt">We Deploy and Operate Infrastructure on Public Clouds like AWS and Azure along with Hybrid Datacenter Integrations. Offering scalability, flexibility, and cost-efficiency enabling businesses to innovate and grow rapidly.</p>

								</div>
							</div>
							<div className="col-md-5 order-md-1">
								<div className="lftbox">
									<picture className="imgbox"> <img src={ooimg04} alt="ooimg04" /> </picture>
								</div>
							</div>
						</div>
					</div>
					<div className="secbox"
						data-aos="zoom-in-up"
						data-aos-duration="1000"
						data-aos-easing="ease-in-sine"
						data-aos-anchor-placement="top-center"
					>
						<div className="row">
							<div className="col-md-7">
								<div className="lftbox">
									<h4 className="h4txt arwimg lftarwimg">MAINTENANCE CONTRACTS</h4>
									<p className="ptxt">We run SLA Based or Scope-Based contracts for Post-Implementation Services along with Hardware Maintenance Services.</p>

								</div>
							</div>
							<div className="col-md-5">
								<div className="rytbox">
									<picture className="imgbox"> <img src={ooimg05} alt="ooimg05" /> </picture>
								</div>
							</div>
						</div>
					</div>
					<div className="secbox"
						data-aos="zoom-in-up"
						data-aos-duration="1000"
						data-aos-easing="ease-in-sine"
					// data-aos-anchor-placement="top-center"
					>
						<div className="row">
							<div className="col-md-7 order-md-2">
								<div className="rytbox">
									<h4 className="h4txt arwimg rytarwimg">RESOURCING AND STAFFING</h4>
									<p className="ptxt">Effective resourcing and staffing ensure that an organization has the right people in the right roles at the right time. We provide On-Site Engineers, Resident Engineers or Staffing across technologies including Application Resources.</p>

								</div>
							</div>
							<div className="col-md-5 order-md-1">
								<div>
									<picture className="imgbox"> <img src={ooimg06} alt="ooimg06" /> </picture>
								</div>
							</div>
						</div>
					</div>

					<div className="secbox"
						data-aos="zoom-in-up"
						data-aos-duration="1000"
						data-aos-easing="ease-in-sine"
						data-aos-anchor-placement="top-center">
						<div className="row">
							<div className="col-md-7">
								<div className="lftbox">
									<h4 className="h4txt arwimg lftarwimg">PASSIVE AND SURVEYS</h4>
									<p className="ptxt">We provide valuable tools for gathering insights and understanding the need by doing Passive Survey and Wireless Surveys along with passive work for Site building or Readiness.</p>

								</div>
							</div>
							<div className="col-md-5">
								<div className="rytbox">
									<picture className="imgbox"> <img src={ooimg07} alt="ooimg07" /> </picture>
								</div>
							</div>
						</div>
					</div>
					<div className="backnav btm mt-5">
						<Link to="/"><img src={bkbtn} alt="bkbtn" srcset="" /> </Link>
					</div>
				</div>
			</div>
		</div>

	)
}

export default OurOfferings