import React from 'react'
import bkbtn from '../assets/images/bkbtn.png'
import opimg1 from '../assets/images/opimg1.png'
import opimg2 from '../assets/images/opimg2.png'
import opimg3 from '../assets/images/opimg3.png'
import opimg4 from '../assets/images/opimg4.png'
import opimg5 from '../assets/images/opimg5.png'
import { Link } from 'react-router-dom'


function OurProject() {
	return (
		<div className="inr_sec opsec">
			<div className="container">
				<div className="boxsec">
					<div className="backnav tp">
						<Link to="/"><img src={bkbtn} alt="bkbtn" srcset="" /> </Link>
					</div>
					<div className="hedbox">
						<h3 className="h3txt tparwimg arwimg">OUR PROJECTS</h3>
						<p className="ptxt">At IDENTIQA, we pave the way for a technologically advanced future, driving innovation and creating value for our clients every step of the way. Together, we can achieve extraordinary outcomes in the digital world. Our core team successfully completed major projects –</p>
					</div>
					<div className="secbox"
						data-aos="zoom-in-up"
						data-aos-duration="1000"
						data-aos-easing="ease-in-sine"
					// data-aos-anchor-placement="top-center"	
					>
						<div className="row">
							<div className="col-md-7">
								<div className="lftbox">
									<h4 className="h4txt arwimg lftarwimg">NETWORK PROJECTS</h4>
									<ul className="lisbx">
										<li>Green field ACI deployment on 2 Spine and 6 leaf architecture.</li>
										<li>SD WAN implementation for 47 retail outlets on Fortinet.</li>
										<li>Infoblox DNS migration from On Prem to AWS and DNS based application routing.</li>
										<li>F5 BIG IP Application Load Balancer and SSL off loader deployment for 10 application farms.</li>
										<li>Brown field migration to ACI from N5k and N2k for 42 racks.</li>
										<li>SD WAN for 118 retail outlets on Meraki SD WAN across two geographics.</li>
									</ul>
								</div>
							</div>
							<div className="col-md-2">
								<div className="rytbox"
									data-aos="slide-right"
									data-aos-duration="1000"
									data-aos-easing="ease-in-sine"
								>
									<picture className="imgbox"> <img src={opimg1} alt="opimg1" /> </picture>
								</div>
							</div>
						</div>
					</div>
					<div className="secbox bgwrld"
						data-aos="zoom-in-up"
						data-aos-duration="1000"
						data-aos-easing="ease-in-sine"
						data-aos-anchor-placement="top-center"
					>
						<div className="row">
							<div className="col-md-7">
								<div className="lftbox">
									<h4 className="h4txt arwimg lftarwimg">SECURITY PROJECTS</h4>
									<ul className="lisbx">
										<li>IPSec and remote VPN deployments.</li>
										<li>Cisco NAC with posturing and profiling for 1800 users.</li>
										<li>Keysight Packet Broker deployment for Global traffic analysis.</li>
										<li>MDM and DLP deployment for 190 users on M365.</li>
										<li>Multiple firewall deployments for Cisco Fire Power, Palo Alto and Checkpoint</li>
										<li>End user AV and DLP deployments.</li>
										<li>Cisco umbrella for cloud-based DNS security.</li>
										<li>Palo Alto fire adoption with migration from existing services.</li>
									</ul>
								</div>
							</div>
							<div className="col-md-3">
								<div className="rytbox"
									data-aos="slide-right"
									data-aos-duration="1000"
									data-aos-easing="ease-in-sine">
									<picture className="imgbox"> <img src={opimg2} alt="opimg2" /> </picture>
								</div>
							</div>
						</div>
					</div>
					<div className="secbox"
						data-aos="zoom-in-up"
						data-aos-duration="1000"
						data-aos-easing="ease-in-sine"
						data-aos-anchor-placement="top-center"
					>
						<div className="row">
							<div className="col-md-9">
								<div className="lftbox">
									<h4 className="h4txt arwimg lftarwimg">OTHER MAJOR PROJECTS</h4>
									<ul className="lisbx" >
										<li>Career grade F5 DNS management and support for Global ISP.</li>
										<li>Palo Alto and Meraki implementation and deployment for 14 countries in Africa.</li>
										<li>Arbor DDoS implementation and support for Global ISP.</li>
										<li>Azure cloud-based GPS monitoring and tracking solutions for Gol.</li>
										<li>5G Readiness Network upgrade for Global ISP across 44 locations.</li>
										<li>B2B technical support for 5000 locations across India for network issues.</li>
										<li>Infrastructure builds on Azure Cloud for Hospital Digitization for ABHA scheme for state government.</li>
									</ul>
								</div>
							</div>
							<div className="col-md-3">
								<div className="rytbox"
									data-aos="slide-right"
									data-aos-duration="1000"
									data-aos-easing="ease-in-sine"
								>
									<picture className="imgbox"> <img src={opimg3} alt="opimg3" /> </picture>
								</div>
							</div>
						</div>
					</div>
					<div className="secbox"
						data-aos="zoom-in-up"
						data-aos-duration="1000"
						data-aos-easing="ease-in-sine"
						data-aos-anchor-placement="top-center"
					>
						<div className="row">
							<div className="col-md-9">
								<div className="lftbox">
									<h4 className="h4txt arwimg lftarwimg">RESOURCE AND HARDWARE PROJECTS</h4>
									<ul className="lisbx">
										<li>Resource Augmentation for L1, L2, L3, and L4 resources for the JIO Convention Centre in Mumbai.</li>
										<li>SLA-based for IT infrastructure.</li>
										<li>Skill Based Resource Augmentation for leading OEMs like DellEMC and HP.</li>
										<li>PNB bank managed services for the network.</li>
										<li>Data center and Campus cabling as part of build projects.</li>
										<li>Wireless surveys as part of Wireless deployments.</li>
									</ul>
								</div>
							</div>
							<div className="col-md-3">
								<div className="rytbox"
									data-aos="slide-right"
									data-aos-duration="1000"
									data-aos-easing="ease-in-sine">
									<picture className="imgbox"> <img src={opimg4} alt="opimg4" /> </picture>
								</div>
							</div>
						</div>
					</div>

					<div className="backnav btm mt-5">
						<Link to="/"><img src={bkbtn} alt="bkbtn" srcset="" /> </Link>
					</div>
				</div>
			</div>
		</div>

	)
}

export default OurProject