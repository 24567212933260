import React from 'react'
import { Link } from 'react-router-dom'

function NotFound() {
    return (
        <div className="inr_sec bgclr02 scrn02 fngrbg02">
            <div className="container">
                <div className="boxsec ">
                    <div className="lftbox">
                        <h3 className="h3txt">404</h3>
                        <h2>Page Not Found</h2>
                        <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
                        <Link to="/" className="text-decoration-underline">Go to Home</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound