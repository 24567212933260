import React from 'react'
import hweimg1 from '../assets/images/hweimg1.png'
import hweimg2 from '../assets/images/hweimg2.png'
import hweimg3 from '../assets/images/hweimg3.png'
import hweimg4 from '../assets/images/hweimg4.png'
import hweimg5 from '../assets/images/hweimg5.png'
import hweimg6 from '../assets/images/hweimg6.png'
import hweimg7 from '../assets/images/hweimg7.png'
// import back_nav_icon from '../assets/images/left-arrow-back-svgrepo-com (1).svg'
import { Link } from 'react-router-dom'
import bkbtn from '../assets/images/bkbtn.png'


// console.log(logos)
function HowWeExcute() {
	return (
		<div className="inr_sec hwesec">
			<div className="container">
				<div className="boxsec">
					<div className="backnav tp">
						<Link to="/"><img src={bkbtn} alt="bkbtn" srcset="" /> </Link>
					</div>
					<div className="hedbox">
						<h3 className="h3txt tparwimg arwimg">HOW WE EXECUTE</h3>
					</div>
					<div className="secbox"
						data-aos="zoom-in-up"
						data-aos-duration="1000"
						data-aos-easing="ease-in-sine"
					// data-aos-anchor-placement="top-center"
					>
						<div className="row">
							<div className="col-md-8">
								<div className="lftbox">
									<h4 className="h4txt arwimg lftarwimg">Datacentre design and build</h4>

									<ul className="lisbx">
										<li>Solution Design for Application Infrastructure, Branch Network, and User across Zero Touch Architecture.</li>
										<li>Built Application Flow concerning network traffic and security best practices.</li>
										<li>Security Architecture for Cloud Infrastructure, Branch Secure access, User Identity, and Access control.</li>
										<li>Phased Implementation of Proposed Solution along with ramp-up plan for Transition to Operations.</li>
										<li>Managing and operating the infrastructure 24x7 for ~100 Sites with 500 users with 100% YoY projection.</li>
									</ul>
									<div className="clntsec">
										<ul>
											<li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture1.png`} alt="clients" /></li>
											<li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture2.png`} alt="clients" /></li>
											<li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture3.png`} alt="clients" /></li>
											<li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture4.png`} alt="clients" /></li>
											<li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture9.png`} alt="clients" /></li>

											<li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture5.png`} alt="clients" /></li>
											<li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture6.png`} alt="clients" /></li>
											<li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture7.png`} alt="clients" /></li>
											<li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture10.png`} alt="clients" /></li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="rytbox">
									<picture className="imgbox"> <img src={hweimg1} alt="hweimg1" /> </picture>
								</div>
							</div>
						</div>
					</div>
					<div className="secbox"
						data-aos="zoom-in-up"
						data-aos-duration="1000"
						data-aos-easing="ease-in-sine"
						data-aos-anchor-placement="top-center"
					>
						<div className="row">
							<div className="col-md-7 order-md-2">
								<div className="rytbox" >
									<h4 className="h4txt arwimg rytarwimg">MANAGED DNS SERVICES</h4>

									<p className="ptxt">Managed DNS services involved outsourcing the DNS management to specialized providers. The providers operate a globally distributed network of DNS servers that resolve domain names into IP addresses, ensuring that users can reach websites, applications, and services efficiently and reliably while focusing on their core business operations.</p>

									<p className="ptxt">We at IDENTIQA ensure users (our clients) through DNS - Improved performance, Increased reliability, Enhanced security, Cost efficiency, and simplified management.</p>
									<p className="ptxt">Our objective is to –</p>

									<ul className="lisbx">
										<li>Managed DNS Infrastructure for the ISP across 17 Circles in India.</li>
										<li>SLA-based 24x7 support.</li>
										<li>Manage and operate the existing install base of the F5 and Infoblox infrastructure.</li>
										<li>Design and Execute hardware and software upgrades.</li>
										<li>Build capacity augmentation plan and execution.</li>
										<li>Configuration build and validation as per ISP and GoI guidelines.</li>
										<li>Field Support for hardware replacement and RMA.</li>
									</ul>
								</div>
							</div>

							<div className="col-md-5 order-md-1">
								<div className="lftbox"
									data-aos="slide-left"
									data-aos-duration="1000"
									data-aos-easing="ease-in-sine">
									<picture className="imgbox">
										<img src={hweimg2} alt="hweimg2" />
									</picture>
								</div>
								<div className="clntsec text-md-center">
									<ul>
										<li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture6.png`} alt="clients" /></li>
										<li className="ms-md-4"><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture11.png`} alt="clients" /></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="secbox"
						data-aos="zoom-in-up"
						data-aos-duration="1000"
						data-aos-easing="ease-in-sine"
						data-aos-anchor-placement="top-center"
					>
						<div className="row">
							<div className="col-md-7">
								<div className="lftbox">
									<h4 className="h4txt arwimg lftarwimg">MANAGED DDoS SERVICES</h4>
									<p className="ptxt">Managed DDoS (Distributed Denial of Service) protection services are specialized solutions provided to our clients to protect organizations from DDoS attacks. By leveraging the expertise and infrastructure of specialized providers, businesses can ensure their services remain available, secure, and performant, even in the face of large-scale attacks. This allows organizations to focus on their core operations without worrying about the impact of malicious traffic. Through these DDos services, we ensure our clients -</p>

									<ul className="lisbx">
										<li>Manage DDoS Infrastructure for the ISP across Globe.</li>
										<li>ISP is using NetScout as an OEM for the Deployment at IXP.</li>
										<li>SLA-based 24x7 support.</li>
										<li>Manage and operate the existing install base of the NetScout in India, Los Angeles, Singapore, and MRS.</li>
										<li>Design and Execute hardware and software upgrades.</li>
										<li>Build capacity augmentation plan and execution.</li>
										<li>Reporting of traffic scrubbing and Data transfer.</li>
										<li>Field Support for hardware replacement and RMA.</li>
									</ul>
								</div>
							</div>
							<div className="col-md-5">
								<div className="rytbox"
									data-aos="slide-right"
									data-aos-duration="1000"
									data-aos-easing="ease-in-sine"
								>
									<picture className="imgbox"> <img src={hweimg3} alt="hweimg3" /> </picture>
								</div>
								<div className="clntsec text-md-center">
									<ul className="list-unstyled">
										<li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture13.png`} alt="clients" /></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="secbox"
						data-aos="zoom-in-up"
						data-aos-duration="1000"
						data-aos-easing="ease-in-sine"
						data-aos-anchor-placement="top-center"
					>
						<div className="row">
							<div className="col-md-7 order-md-2">
								<div className="rytbox">
									<h4 className="h4txt arwimg rytarwimg">IMPLEMENTATION SERVICES</h4>
									<p className="ptxt">Implementation services are essential for successfully deploying and integrating new technologies within an organization. By partnering with IDENTIQA, Businesses can ensure their new systems are effectively implemented, optimized, and aligned with their strategic goals. This led to improved performance, reduced risks, and greater user satisfaction.</p>

									<ul className="lisbx">
										<li>Our objective is to Design and Implement network infrastructure across 14 countries.</li>
										<li>Design and Implement Palo Alto Firewalls and Meraki Appliances.</li>
										<li>Design the network architecture based on the current deployment.</li>
										<li>Built migration plan for each site and service.</li>
										<li>Migrate existing configurations to the proposed appliances.</li>
										<li>Build cloud policies for the Meraki appliances.</li>
										<li>Migrate the traffic to the infrastructure during downtime.</li>
									</ul>
								</div>
							</div>

							<div className="col-md-5 order-md-1">
								<div className="lftbox"
									data-aos="slide-left"
									data-aos-duration="1000"
									data-aos-easing="ease-in-sine">
									<picture className="imgbox"> <img src={hweimg4} alt="hweimg4" /> </picture>

								</div>
								<div className="clntsec text-md-center">
									<ul>
										<li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture7.png`} alt="clients" /></li>
										<li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture14.png`} alt="clients" /></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="secbox"

						data-aos="zoom-in-up"
						data-aos-duration="1000"
						data-aos-easing="ease-in-sine"
						data-aos-anchor-placement="top-center">
						<h4 className="h4txt arwimg lftarwimg">SD-WAN IMPLEMENTATION SERVICES</h4>
						<div className="row">
							<div className="col-md-7">
								<div className="lftbox">
									<p className="ptxt">SD-WAN (software-defined wide area network) services are crucial for organizations looking to modernize their network infrastructure, improve performance, and enhance security. We provide this service to our clients which involves the deployment and integration of SD-WAN technology to optimize and secure the network connectivity of the organization. It ensures the efficient management of WAN resources and improves overall network performance.</p>
									<ul className="lisbx">
										<li>We Design and Implement SDWAN for Brown and Green field Sites.</li>
										<li>Deployment of Meraki SDWAN for 192 locations across India.</li>
										<li>Design Workshop with client to understand current architecture and traffic flow.</li>
										<li>Design the network architecture based on the current deployment.</li>
										<li>Built migration plan for each site and service.</li>
										<li>Build cloud policies for the Meraki appliances.</li>
										<li>Configured and installed the setup in parallel for traffic testing and validation.</li>
										<li>Migrated the traffic to the infrastructure during downtime.</li>
										<li>Deployed VMX on Cloud for Cloud Traffic.</li>
										<li>Phased Migration of 192 locations across India to MX67.</li>
									</ul>
								</div>
							</div>
							<div className="col-md-5">
								<div className="rytbox"
									data-aos="slide-right"
									data-aos-duration="1000"
									data-aos-easing="ease-in-sine"
								>
									<picture className="imgbox"> <img src={hweimg5} alt="hweimg5" /> </picture>
								</div>
								<div className="clntsec text-md-center">
									<ul>
										<li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture7.png`} alt="clients" /></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="secbox"
						data-aos="zoom-in-up"
						data-aos-duration="1000"
						data-aos-easing="ease-in-sine"
						data-aos-anchor-placement="top-center"
					>
						<div className="row">
							<div className="col-md-8 order-md-2">
								<div className="rytbox">
									<h4 className="h4txt arwimg rytarwimg">CLOUD IMPLEMENTATION AND OPERATIONS</h4>
									<p className="ptxt">Cloud implementation services involve the process of planning, deploying, and managing cloud computing solutions to optimize an organization's IT infrastructure. These services are crucial for organizations to leverage the benefits of cloud computing and to achieve greater scalability, performance, and cost-efficiency. By using our Cloud implementation services, IDENTIQA helps organizations stay competitive, innovate rapidly, and adapt to changing market demand. Here we –</p>
									<ul className="lisbx">
										<li>Build infrastructure for Digital Hospital Project.</li>
										<li>Built infrastructure on Azure as per application requirements.</li>
										<li>Designed IP Schema and VNET for inter-VPC peering.</li>
										<li>Configured Domain, SSL, and VPN policies for access to policies.</li>
										<li>Secured the infrastructure with Firewall and IAM policies.</li>
										<li>Integrated remote application instances to the cloud in a distributed deployment across 100+ hospitals across the state.</li>
										<li>Managing and operating the infrastructure 24x7.</li>
									</ul>
								</div>
							</div>
							<div className="col-md-4 order-md-1">
								<div className="lftbox"
									data-aos="slide-left"
									data-aos-duration="1000"
									data-aos-easing="ease-in-sine">

									<picture className="imgbox"> <img src={hweimg6} alt="hweimg6" /> </picture>

								</div>
								<div className="clntsec text-md-center">
									<ul>
										<li><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture1.png`} alt="clients" /></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="secbox"
						data-aos="zoom-in-up"
						data-aos-duration="1000"
						data-aos-easing="ease-in-sine"
						data-aos-anchor-placement="top-center"
					>
						<h4 className="h4txt arwimg lftarwimg">CLOUD INFRASTRUCTURE MANAGED SERVICES</h4>
						<div className="row">
							<div className="col-md-8">
								<div className="lftbox">
									<p className="ptxt">Cloud infrastructure managed services are essential for organizations looking to leverage the full potential of cloud computing while minimizing the complexities and risks associated with managing cloud environments.</p>
									<p className="ptxt">
										By partnering with IDENTIQA for these services, we ensure our clients their cloud infrastructure is secure, cost-efficient, and aligned with their strategic goals, which allows their organization to focus on innovation and growth while leaving the day-to-day management of cloud resources to our experts. With our services we –
									</p>
									<ul className="lisbx">
										<li>Built SD-WAN controller on Azure as per application requirements.</li>
										<li>Solution Design for end Client on behalf of ISP.</li>
										<li>Solution Sizing based on end customer discussions.</li>
										<li>Perform POC on behalf of ISP for agreed success criteria.</li>
										<li>Implementation and Migration of Proposed Solution.</li>
										<li>Managing 23 of ISP's customers on Fortinet SDWAN across India.</li>
										<li>Managing and operating the infrastructure 24x7.</li>

									</ul>
									<div className="clntsec">
										<ul className="list-unstyled">
											<li className="ms-md-4"><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture1.png`} alt="clients" /></li>
											<li className="ms-md-4"><img src={`${process.env.PUBLIC_URL}/public-images/how-we-excute/Picture19.png`} alt="clients" /></li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="rytbox" data-aos="slide-right"
									data-aos-duration="1000"
									data-aos-easing="ease-in-sine">
									<picture className="imgbox"> <img src={hweimg7} alt="hweimg7" /> </picture>
								</div>
							</div>
						</div>
					</div>
					<div className="backnav btm mt-5">
						<Link to="/"><img src={bkbtn} alt="bkbtn" srcset="" /> </Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default HowWeExcute