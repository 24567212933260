import React from 'react'
import ovimg01 from '../assets/images/ovimg01.png'
import ovimg02 from '../assets/images/ovimg02.png'
import bkbtn from '../assets/images/bkbtn.png'
import { Link } from 'react-router-dom'
// import back_nav_icon from '../assets/images/left-arrow-back-svgrepo-com (1).svg'

function OurVision() {
	return (
		<div className="inr_sec ovsec">
			<div className="container">
				<div className="boxsec">
					<div className="backnav tp">
						<Link to="/"><img src={bkbtn} alt="bkbtn" srcset="" /> </Link>
					</div>
					<div className="hedbox">
						<h3 className="h3txt tparwimg arwimg"

						>OUR Vision</h3>
						<p className="ptxt">At IDENTIQA, we believe in a client-centric approach that prioritizes understanding and addressing the specific needs of each client. We focus on continuous innovation and improvement, fostering a culture of excellence and collaboration.
							We are working to make a positive impact on society through technology-driven solutions and corporate social responsibility initiatives.
						</p>
					</div>

					<div className="secbox"
						data-aos="zoom-in-up"
						data-aos-duration="1000"
						data-aos-easing="ease-in-sine"
					// data-aos-anchor-placement="center-top"
					>
						<div className="row">
							<div className="col-md-7">
								<div className="lftbox">
									<h4 className="h4txt arwimg lftarwimg">Core Strengths -</h4>

									<ul className="lisbx">
										<li>Core technical team with OEM.</li>
										<li>Core technical team with domain knowledge.</li>
										<li>Certifications and cross-domain knowledge.</li>
										<li>Hybrid or Remote Delivery Model.</li>
										<li>Skills in line with project requirements.</li>
										<li>Single point of escalation for technical calls.</li>
									</ul>
								</div>
							</div>
							<div className="col-md-5">
								<div className="rytbox"
									data-aos="slide-right"
									data-aos-duration="1000"
									data-aos-easing="ease-in-sine"
								// data-aos-offset="200"
								>

									<picture className="imgbox"> <img src={ovimg01} alt="ovimg01" /> </picture>
								</div>
							</div>
						</div>
					</div>


					<div className="secbox" data-aos="zoom-in-up"
						data-aos-duration="1000"
						data-aos-easing="ease-in-sine"
						data-aos-anchor-placement="top-center">
						<div className="row">
							<div className="col-md-7 order-md-2">
								<div className="rytbox">
									<h4 className="h4txt arwimg rytarwimg">IDENTIQA Core Team Capabilities -</h4>
									<ul className="lisbx">
										<li>Delivering HLD, LLD, Migration, and Implementation plans for clients.</li>
										<li>Delivering green field and brown field projects for clients.</li>
										<li>ITSM and ITIL process driven.</li>
										<li>Delivering Complex and multi-technology implementation and integrations.</li>
									</ul>
								</div>
							</div>


							<div className="col-md-5 order-md-1">
								<div className="lftbox"
									data-aos="slide-left"
									data-aos-duration="1000"
									data-aos-easing="ease-in-sine"
								// data-aos-offset="200"
								>
									<picture className="imgbox"> <img src={ovimg02} alt="ovimg02" /> </picture>
								</div>
							</div>

						</div>
					</div>



					<div className="clntsec mt-md-5 pt-md-4"
						data-aos="zoom-in-up"
						data-aos-duration="1000"
						data-aos-easing="ease-in-sine"
						data-aos-anchor-placement="top-center"
					>
						<ul>

							<li><img src={`${process.env.PUBLIC_URL}/public-images/our-vision/Picture20.png`} alt="clients" /></li>
							<li><img src={`${process.env.PUBLIC_URL}/public-images/our-vision/Picture21.png`} alt="clients" /></li>
							<li><img src={`${process.env.PUBLIC_URL}/public-images/our-vision/Picture22.png`} alt="clients" /></li>
							<li><img src={`${process.env.PUBLIC_URL}/public-images/our-vision/Picture23.png`} alt="clients" /></li>
							<li><img src={`${process.env.PUBLIC_URL}/public-images/our-vision/Picture24.png`} alt="clients" /></li>
							<li><img src={`${process.env.PUBLIC_URL}/public-images/our-vision/Picture25.png`} alt="clients" /></li>
							<li><img src={`${process.env.PUBLIC_URL}/public-images/our-vision/Picture32.png`} alt="clients" /></li>
							<li><img src={`${process.env.PUBLIC_URL}/public-images/our-vision/Picture33.png`} alt="clients" /></li>
							<li><img src={`${process.env.PUBLIC_URL}/public-images/our-vision/Picture26.png`} alt="clients" /></li>
							<li><img src={`${process.env.PUBLIC_URL}/public-images/our-vision/Picture27.png`} alt="clients" /></li>
							<li><img src={`${process.env.PUBLIC_URL}/public-images/our-vision/Picture28.png`} alt="clients" /></li>
							<li><img src={`${process.env.PUBLIC_URL}/public-images/our-vision/Picture29.png`} alt="clients" /></li>

							<li><img src={`${process.env.PUBLIC_URL}/public-images/our-vision/Picture30.png`} alt="clients" /></li>
							<li><img src={`${process.env.PUBLIC_URL}/public-images/our-vision/Picture31.png`} alt="clients" /></li>

						</ul>
					</div>

					<div className="backnav btm mt-5">
						<Link to="/"><img src={bkbtn} alt="bkbtn" srcset="" /> </Link>
					</div>

				</div>
			</div>
		</div>

	)
}

export default OurVision