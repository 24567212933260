import React from 'react'

function PrivacyPolicy() {
    return (
        <div className="inr_sec bgclr02 scrn02 fngrbg02">
            <div className="container">
                <div className="boxsec ">
                    <div className="lftbox">
                        <h4 >Privacy Policy</h4>
                        <p >We respect your privacy and are committed to protecting personally identifiable information you may provide to Identiqa Consulting Private Limited through this website. This Privacy Policy applies to www.identiqa.co.in  and www.identiqa.tech (hereinafter, “us”, “we”, or “<a href="https://www.identiqa.co.in" >https://www.identiqa.co.in</a> and <a href="https://www.identiqa.tech" >https://www.identiqa.tech</a> ”).</p>

                        <p > We have adopted this privacy policy (“Privacy Policy”) to explain what information may be collected on our website, how we use this information, and under what circumstances we may disclose the information to third parties. This Privacy Policy applies only to information we collect through the website and does not apply to our collection of information from other sources.</p>

                        <p > This Privacy Policy, together with the Terms and conditions posted on our website, set forth the general rules and policies governing your use of our website. Depending on your activities when visiting our website, you may be required to agree to additional terms and conditions.</p>

                        <h4  >Website Visitors</h4>
                        <p  >Similar to most website operators, Identiqa Consulting Private Limited collects non-personally identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. Identiqa Consulting Private Limited purpose in collecting non-personally identifying information is to better understand how our visitors use our website. From time to time, Identiqa Consulting Private Limited may release non-personally identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website.</p>

                        <p >
                            Identiqa Consulting Private Limited may also collect potentially personally identifying information, such as Internet Protocol (IP) addresses. Identiqa Consulting Private Limited will only discloses such information under the same circumstances that it uses and discloses personally identifying information as described below.
                        </p>


                        <h4  >Gathering of Personally Identifying Information</h4>
                        <p >
                            Certain visitors to Identiqa Consulting Private Limited websites choose to interact with Identiqa Consulting Private Limited in ways that require Identiqa Consulting Private Limited to gather personally identifying information. The amount and type of information that Identiqa Consulting Private Limited gathers depends on the nature of the interaction. For example, we ask visitors who sign up for our newsletter or download information to provide a name and email address. No mobile information will be shared with third parties/affiliates for marketing/promotional purposes. All other categories exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties.
                        </p>

                        <h4 >Security</h4>
                        <p >The security of your personal information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>

                        <h4 >Links to External Sites</h4>
                        <p >
                            Our Service may contain links to external sites that are not operated by us. If you click on a third-party link, you will be directed to that third-party’s site. We strongly advise you to review the Privacy Policy and terms and conditions of every site you visit. We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites, products or services.
                        </p>

                        <h4 >Aggregated Statistics</h4>
                        <p >Identiqa Consulting Private Limited may collect statistics about the behavior of visitors to its website. Identiqa Consulting Private Limited does not display this information publicly or provide it to others. We never disclose your personally identifying information to others. Any information you submit to this website will be kept private and used solely to conduct business and stay in touch with you and our business partners.</p>


                        <h4 >Cookies</h4>
                        <p >
                            To enrich and perfect your online experience, Identiqa Consulting Private Limited uses “cookies” to display personalized content, and store your preferences on your computer. A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor’s browser provides to the website each time the visitor returns. Identiqa Consulting Private Limited uses cookies to help Identiqa Consulting Private Limited identify and track visitors, and their usage of <a href='https://www.identiqa.co.in'>https://www.identiqa.co.in</a> and <a href="https://www.identiqa.tech">https://www.identiqa.tech</a>. Identiqa Consulting Private Limited visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using Identiqa Consulting Private Limited websites, with thedrawback that certain features of our Identiqa Consulting Private Limited website may not function properly without the aid of cookies. By continuing to navigate our website without changing your cookie settings, you hereby acknowledge and agree to Identiqa Consulting Private Limited use of cookies.
                        </p>

                        <h4 >Privacy Policy Changes</h4>
                        <p >Although most changes are likely to be minor, Identiqa Consulting Private Limited may change it from time to time, and in Identiqa Consulting Private Limited sole discretion. Identiqa Consulting Private Limited encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy