import React from 'react'
import hlogo from '../assets/images/hlogo.png'
// import blueLogo from "../assets/images/Identiqa-blue-logo.png"
import { Link, useLocation } from 'react-router-dom'

function Header() {

    const [isMenuOpen, setIsMenuOpen] = React.useState(false)
    const [isSticky, setIsSticky] = React.useState(false);
    const menuRef = React.useRef(null);
    const routeLocation = useLocation()
    const { pathname } = routeLocation

    const toggleMenu = () => {
        setIsMenuOpen((prev) => !prev);
    };



    const handleClickOutside = (event) => {
        // Check if the click is outside the menu
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsMenuOpen(false);
        }
    };


    React.useEffect(() => {
        // Attach the event listener when the component mounts
        document.addEventListener("click", handleClickOutside);

        // Cleanup the event listener when the component unmounts
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);


    // scroll handler
    React.useEffect(() => {

        const handleScroll = () => {
            const offset = window.scrollY;
            const stickyPoint = 63; // You can change this value to whatever suits your needs
            setIsSticky(offset > stickyPoint);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);




    return (
        <header className={`hdrsec ${pathname !== "/" && isSticky && 'inr_sec_scroll-header-bg'} ${pathname === "/" && isSticky ? 'header-bg' : ''}`} >
            <nav className="navbar navbar-expand-lg p-0">
                <div className="container-fluid">
                    <Link className="navbar-brand logo default-cursor" to="/">
                        <img src={pathname !== "/" && isSticky ? hlogo : hlogo} alt="logo" />
                    </Link><div className="mbtn">
                        <div role="navigation">
                            <div id="menuToggle">
                                <input type="checkbox" onChange={(e) => toggleMenu()} checked={isMenuOpen} ref={menuRef} />
                                <span className={`${pathname !== "/" && false && 'inr_sec_scroll-navigation-btn-bg'}`}>xyz</span>
                                <span className={`${pathname !== "/" && false && 'inr_sec_scroll-navigation-btn-bg'}`}>xyz</span>
                                <span className={`${pathname !== "/" && false && 'inr_sec_scroll-navigation-btn-bg'}`}>xyz</span>
                                <ul id="menu">
                                    <li><Link to="/" className={`${pathname === '/' ? 'active-m' : ''}`}>Home</Link></li>
                                    <li><Link to="/our-vision" className={`${pathname === '/our-vision' ? 'active-m' : ''}`}>Our Vision</Link></li>
                                    <li><Link to="/our-offering" className={`${pathname === '/our-offering' ? 'active-m' : ''}`}>Our Offerings</Link></li>
                                    <li><Link to="/how-we-execute" className={`${pathname === '/how-we-execute' ? 'active-m' : ''}`}>How We Execute</Link></li>
                                    <li><Link to="/our-project" className={`${pathname === '/our-project' ? 'active-m' : ''}`}>Our Projects</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header